import BaseButton from '../../base-components/BaseButton'
import './styles.scss'

const ContactForm = () => {
    return (
        <div className="contact-form">
            <h3>Свържете се с Нас</h3>
            <form target="_blank" action="https://formsubmit.co/9f3e2116c460551fe01d69083b23d5f1" method="POST">
                <div className="form-group">
                    <div className="form-row">
                        <div>
                            <label className='form-label'>Име и фамилия</label>
                            <input type="text" name="name" className="form-control" placeholder="Вашето име" required />
                        </div>
                        <div>
                            <label className='form-label'>Email адрес</label>
                            <input type="email" name="email" className="form-control" placeholder="Email адрес" required />
                            <input type="hidden" name="_cc" value="pavel_ltu@abv.bg"></input>
                            <input type="hidden" name="_template" value="box"></input>
                        </div>
                        <div>
                            <label className='form-label'>Телефонен номер (опция)</label>
                            <input type="tel" name="phone" className="form-control" placeholder="Телефонен номер за обратна връзка" />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className='form-label'>Съобщение</label>
                    <textarea placeholder="Вашето съобщение" className="form-control" name="message" rows="5" required></textarea>
                </div>
                <BaseButton type="submit" title="Изпрати" />
            </form>
        </div>
    )
}
export default ContactForm