import { useNavigate } from "react-router-dom";
import "./ErroPage.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BaseButton from "../../components/base-components/BaseButton";
import Title from "../../components/title/Title";

const ErrorPage = () => {
    const history = useNavigate();
    function handleClick() {
        history("/");
    }
    return (
        <>
            <Helmet>
                <title>Drem Home-19 | Грешка 404</title>
                <meta name="description"
                    content="Намерете услуги за търговски недвижими имоти и стратегии за инвестиции в имоти. Работим със собственици, наематели и инвеститори на местните и национални пазари." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0, user-scalable=0" />
                <meta name="keywords" content="Недвижими имоти, продажба, под наем" />
                <meta name="author" content="Drem Home-19" />
                <meta property="og:title" content="Drem Home-19 | Грешка 404" />
                <meta property="og:description"
                    content="Намерете услуги за търговски недвижими имоти и стратегии за инвестиции в имоти. Работим със собственици, наематели и инвеститори на местните и национални пазари." />
                <meta property="og:image" content="/assets/logo/logoDreamHome19.svg" />
                <meta name="prerender-status-code" content="404"></meta>
            </Helmet>

            <article className="error-page-container">
                <Container>
                    <Row>
                        <Col className="error-content">
                            <Title title1="Страницата " title2="не е намерена"/>
                            <h1>404</h1>
                            <p className="content">Страницата, която търсите, може би е преименувана, премахната или може никога да не съществува в този сайт.</p>
                            <BaseButton title="Върнете се обратно" onClick={handleClick}></BaseButton>
                        </Col>
                    </Row>
                </Container>
            </article>
        </>
    );
};

export default ErrorPage;
