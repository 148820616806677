import {useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types'
import ProductPrice from '../product-price'
import './styles.scss'
import { Link } from 'react-router-dom'

const ProductImage = ({ image, title }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);

    function updateSize() {
      setWidth(ref.current.offsetWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);



  return (
    <div ref={ref} className='product-image' style={{height: (width / 1.2)}}>
      <img src={image} alt={title} className='product-image'/>
    </div>
  )
}

const ProductCardCarousel = ({ image, title, subtitle, status, price, area, id }) => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  return (
    <div className='product-card-carousel'>
      <div className='ribbon'>{status}</div>
      <Link to={`/product/${id}`} onClick={scrollToTop}>
        <ProductImage image={image} title={title} />
      </Link>
      <div className="product-card-info">
        <span className="product-title">{title}&#44;&nbsp;<span>{subtitle}</span></span>
        <div className="product-info">
          <ProductPrice price={price} />
          <p><span>{area}</span> кв.м</p>
        </div>
      </div>
    </div>
  )
}

ProductCardCarousel.defaultProps = {
  image: '',
  title: 'Placeholder',
  price: '0'
}

ProductCardCarousel.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired
}

export default ProductCardCarousel