import './styles.scss'
import CountUp from 'react-countup';

const Counter = ({ Title, Value }) => {
    return (
        <div className='counter-container'>
            {/* <span className='counter-value' data-val={Value}>{Value} +</span> */}
            <CountUp start={0} end={Value} delay={2} className='counter-value' />
            <span className='counter-title'>{Title}</span>
        </div>

    );
};

export default Counter;