import './Title.scss'
import element from '../../assets/logo/logo-element.svg'
import ScrollAnimation from 'react-animate-on-scroll'

const Title = ({ title1, title2, subtitle }) => {

  return (
    <div className='title-container'>
      <ScrollAnimation animateIn="animate__slideInDown">
        <h2>{title1}<span> {title2}</span></h2>
        <p>{subtitle}</p>
      </ScrollAnimation>
      <ScrollAnimation animateIn="animate__fadeInUp">
        <img src={element} alt='Logo element'></img>
      </ScrollAnimation>
    </div>
  )
}

export default Title