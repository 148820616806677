import './SocialIcon.scss';

const SocialIcon = ({ icon, alt, href, target, rel, title, titleBold }) => {
    return (
        <a href={href} target={target} rel={rel} className="social-icon">
            <img src={icon} alt={alt}></img>
            <p>{title}<span>{titleBold}</span></p>
        </a>
    )
}

export default SocialIcon;