import React, { useState, useEffect, Fragment } from 'react'
import './styles.scss'
import { Col, Row } from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll'
import ProductCard from '../product-card'
import LatestProductCard from '../latest-product-card'
import Title from '../title/Title'
import { Data } from '../../data'

const renderLatestCards = (products) => {
  return products.slice(-1).map(product => {
    return (
      <Fragment key={product.id}>
        <LatestProductCard {...product} />
      </Fragment>
    )
  })
}

const renderNewCards = (products) => {
  const lastOffers = products.slice(-5)
  return lastOffers.reverse()
    .map(product => {
      return (
        <Fragment key={product.id}>
          <ProductCard {...product} />
        </Fragment>
      )
    })
}

const NewOffers = () => {
  const [filertFilterNewCards, setFilterNewCards] = useState([]);

  useEffect(() => {
    setFilterNewCards(Data.filter(item => item.status === "Продажба" || item.status === "Под наем"))
  }, []);

  return (
    <section className='new-offers-container'>
      <Row>
        <Col md={12}>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce="true">
            {renderLatestCards(filertFilterNewCards)}
          </ScrollAnimation>
        </Col>
        <Col md={12} className='more-new-offers'>
          <Title title1='Oще актуални' title2='предложения' subtitle='' />
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce="true">
            <section className='product-list'>
              {renderNewCards(filertFilterNewCards)}
            </section>
          </ScrollAnimation>
        </Col>
      </Row>
    </section>
  )
}

export default NewOffers
