import { useState, useEffect, Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import BaseImage from "../base-components/BaseImage";
import BaseButton from '../base-components/BaseButton'
import "./TopNavbar.scss"
import logo from "../../assets/logo/logoDreamHome19.svg"
import realEstates from '../../assets/icons/service2.svg'
import close from '../../assets/icons/close.svg'

const TopNavbar = () => {
  const [scroll, setScroll] = useState(false);

  const [isScrolled, setIsScrolled] = useState(typeof window !== "undefined" && window.pageYOffset > 0);
  const [checked, setChecked] = useState(false);

  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  const openMobileMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  }

  const closeMobileMenu = () => {
    setIsMobileMenuVisible(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    function onscroll() {
      setIsScrolled(window.pageYOffset > 0);
    }

    window.addEventListener("scroll", onscroll);
    return () => window.removeEventListener("scroll", onscroll);
  });

  useEffect(() => {
    if (typeof document == "undefined") {
      return;
    }

    if (isMobileMenuVisible) {
      document.body.style.overflow = "hidden";
    }
    else {
      document.body.style.overflow = "auto";
    }
  }, [isMobileMenuVisible]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        setScroll(window.pageYOffset > 0)
        if (window.pageYOffset > 720) {
          document.querySelector('nav').classList.add('hidden')
          document.querySelector('nav').classList.remove('show')
        } else {
          document.querySelector('nav').classList.remove('hidden')
          document.querySelector('nav').classList.add('show')
        }
      }
      );
    }

  });

  let activeStyle = {
    textDecoration: "underline",
  };

  return (
    <Fragment>
      <nav className={`nav ${scroll ? "scrolled" : ""} page-nav show`}>
        <section className="nav-list">
          <Container>
            <Row>
              <ul>
                <li>
                  <Link to={"/"} onClick={scrollToTop}>
                    <BaseImage img={logo} width="50px" height="50px" />
                  </Link>
                </li>
                <li className="real-estates">
                  <BaseImage img={realEstates} alt="real-estates" width="15px" height="15px" />
                  <NavLink
                    to="/real-estates"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                    onClick={scrollToTop}
                  >
                    {" "}
                    Имоти{" "}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/services"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                    onClick={scrollToTop}
                  >
                    {" "}
                    Услуги{" "}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/about-us"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                    onClick={scrollToTop}
                  >
                    {" "}
                    За нас{" "}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contacts"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                    onClick={scrollToTop}
                  >
                    {" "}
                    Контакти{" "}
                  </NavLink>
                </li>
                <li>
                  <input className="menu-btn" type="checkbox" id="menu-btn" onClick={openMobileMenu} defaultChecked={checked} />
                  <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
                </li>
              </ul>
            </Row>
          </Container>
        </section>
      </nav>

      <nav className={`nav nav-mobile ${isMobileMenuVisible ? "selected" : ""}`}>
        <div className="nav-bar-wrapper">
          <section className='mobile-header'>
            <Link to={"/"} className="logo" onClick={scrollToTop}>
              <BaseImage img={logo} width="50px" height="50px" />
            </Link>
            <BaseButton
              icon={close}
              alt="Close mobile menu"
              onClick={closeMobileMenu}
            />
          </section>
          <section className="nav-bar">
            <section className="nav-list">
              <Container>
                <Row>
                  <ul>
                    <li>
                      <NavLink
                        to="/real-estates"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        onClick={closeMobileMenu}
                      >
                        {" "}
                        Имоти{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/services"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        onClick={closeMobileMenu}
                      >
                        {" "}
                        Услуги{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/about-us"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        onClick={closeMobileMenu}
                      >
                        {" "}
                        За нас{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/contacts"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        onClick={closeMobileMenu}
                      >
                        {" "}
                        Контакти{" "}
                      </NavLink>
                    </li>
                  </ul>
                </Row>
              </Container>
            </section>
          </section>
          <div className="after" onClick={closeMobileMenu}></div>
        </div>
      </nav>
    </Fragment>
  );
};

export default TopNavbar;
