import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import "./ServicesPage.scss";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Main from '../../components/main-title/Main'
import BaseButton from "../../components/base-components/BaseButton";
// import BaseImage from "../../components/base-components/BaseImage";
// import one from '../../assets/icons/service1.svg'
// import two from '../../assets/icons/service2.svg'
// import three from '../../assets/icons/service3.svg'
// import four from '../../assets/icons/service4.svg'

const ServicesPage = () => {
    const [animate, setAnimate] = useState(true);

    useEffect(() => {
        if (window.sessionStorage.getItem("firstLoadAboutUsDone") === null) {
            setAnimate(true)

            window.sessionStorage.setItem("firstLoadAboutUsDone", 3)
        } else {
            setAnimate(false)
        }
    }, [animate]);

    const oneRef = useRef()
    const twoRef = useRef()
    const threeRef = useRef()
    const fourRef = useRef()
    const fiveRef = useRef()
    const sixRef = useRef()
    const sevenRef = useRef()
    const eightRef = useRef()

    const handleToOne = () => {
        oneRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    const handleToTwo = () => {
        twoRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const handleToThree = () => {
        threeRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const handleToFour = () => {
        fourRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const handleToFive = () => {
        fiveRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const handleToSix = () => {
        sixRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const handleToSeven = () => {
        sevenRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const handleToEight = () => {
        eightRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const history = useNavigate();

    function realEstatesClick() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        history("/real-estates");
    }

    return (
        <>
            <Helmet>
                <title>Drem Home-19 | Услуги</title>
                <link rel="icon" type="image/x-icon" href="../../assets/images/favicon-32x32.png"></link>
                <link rel="apple-touch-icon" sizes="57x57" href="../assets/favicon/apple-icon-57x57.png" />
                <link rel="apple-touch-icon" sizes="60x60" href="../assets/favicon/apple-icon-60x60.png" />
                <link rel="apple-touch-icon" sizes="72x72" href="../assets/favicon/apple-icon-72x72.png" />
                <link rel="apple-touch-icon" sizes="76x76" href="../assets/favicon/apple-icon-76x76.png" />
                <link rel="apple-touch-icon" sizes="114x114" href="../assets/favicon/apple-icon-114x114.png" />
                <link rel="apple-touch-icon" sizes="120x120" href="../assets/favicon/apple-icon-120x120.png" />
                <link rel="apple-touch-icon" sizes="144x144" href="../assets/favicon/apple-icon-144x144.png" />
                <link rel="apple-touch-icon" sizes="152x152" href="../assets/favicon/apple-icon-152x152.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="../assets/favicon/apple-icon-180x180.png" />
                <link rel="icon" type="image/png" sizes="192x192" href="../assets/favicon/android-icon-192x192.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="../assets/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="96x96" href="../assets/favicon/favicon-96x96.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="../assets/favicon/favicon-16x16.png" />
                <link rel="manifest" href="../assets/favicon/manifest.json" />
                <link rel="canonical" href="https://dreamhome19.bg/" />
                <meta charSet="utf-8" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="../assets/favicon/ms-icon-144x144.png" />
                <meta name="theme-color" content="#ffffff"></meta>
                <meta name="description"
                    content="Намерете услуги за търговски недвижими имоти и стратегии за инвестиции в имоти. Работим със собственици, наематели и инвеститори на местните и национални пазари." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0, user-scalable=0" />
                <meta name="keywords" content="Недвижими имоти, продажба, под наем" />
                <meta name="author" content="Drem Home-19" />
                <meta property="og:title" content="Drem Home-19 | Услуги" />
                <meta property="og:description"
                    content="Намерете услуги за търговски недвижими имоти и стратегии за инвестиции в имоти. Работим със собственици, наематели и инвеститори на местните и национални пазари." />
                <meta property="og:image" content="../../assets/logo/logoDreamHome19.svg" />
                <meta name="prerender-status-code" content="404"></meta>
            </Helmet>

            <main className='services-main'>
                <Container>
                    <Row className={animate ? "animate" : ""}>
                        <Main title1='Услугите,' title2='които предлагаме' subtitle='Всичко, което Ви е нужно за Вашата сделка' button='Намери' className='services' />
                    </Row>
                </Container>
            </main>
            <article className="services-page-container fade-in">
                <Container>
                    <div className="explore-tabs-container">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                            <Row>
                                <Col sm={12} md={6} lg={5} xl={4}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item onClick={handleToOne}>
                                            <Nav.Link eventKey="1" ><span>01.</span> Професионално консултиране</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={handleToTwo}>
                                            <Nav.Link eventKey="2" ><span>02.</span> Правно и техническо обслужване</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={handleToThree}>
                                            <Nav.Link eventKey="3" ><span>03.</span> Ипотечни кредити</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={handleToFour}>
                                            <Nav.Link eventKey="4" ><span>04.</span> Експертни оценки</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={handleToFive}>
                                            <Nav.Link eventKey="5" ><span>05.</span> Консултации</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={handleToSix}>
                                            <Nav.Link eventKey="6" ><span>06.</span> Професионално фотографско</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={handleToSeven}>
                                            <Nav.Link eventKey="7" ><span>07.</span> Маркетингови проучвания</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={handleToEight}>
                                            <Nav.Link eventKey="8" ><span>08.</span> Устройствени процедури</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={12} md={6} lg={7} xl={8} className='box-context'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="1" ref={oneRef}>
                                            <div className="accent-container" >
                                                <h2>
                                                    <span>Професионално</span> консултиране
                                                </h2>
                                                <p>
                                                    Професионално консултиране в сферата на имотите и предоставяне на коректно обслужване, гласувано доверие в желанието за успех.
                                                </p>
                                                <BaseButton title='Вижте нашите оферти' onClick={realEstatesClick} />
                                            </div>
                                            {/* <BaseImage img={one} alt="Професионално консултиране" width="120" height="120"/> */}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="2" ref={twoRef}>
                                            <div className="accent-container" >
                                                <h2>
                                                    Правно и техническо <span>обслужване</span>
                                                </h2>
                                                <p>
                                                    Правно и техническо обслужване на сделки с недвижими имоти.
                                                </p>
                                            </div>
                                            {/* <BaseImage img={two} alt="Професионално консултиране" width="120" height="120"/> */}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="3" ref={threeRef}>
                                            <div className="accent-container" >
                                                <h2>
                                                    Ипотечни <span>кредити</span>
                                                </h2>
                                                <p>
                                                    Ипотечни кредити за покупка на имоти от водещи банки-партньори.
                                                </p>
                                            </div>
                                            {/* <BaseImage img={three} alt="Професионално консултиране" width="120" height="120"/> */}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="4" ref={fourRef}>
                                            <div className="accent-container" >
                                                <h2>
                                                    <span>Експертни</span> услуги
                                                </h2>
                                                <p>Експертни оценки и консултации за пазарна стойност на недвижими имоти</p>
                                            </div>
                                            {/* <BaseImage img={four} alt="Професионално консултиране" width="120" height="120"/> */}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="5" ref={fiveRef}>
                                            <div className="accent-container">
                                                <h2>
                                                    Консултации
                                                </h2>
                                                <p>
                                                    Консултации в областта на данъчното законодателство при сделки с недвижими имоти
                                                </p>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="6" ref={sixRef}>
                                            <div className="accent-container" >
                                                <h2>
                                                    Професионалнa <span>фотография</span>
                                                </h2>
                                                <p>
                                                    Професионално фотографско заснемане на имоти, пространствено заснемане на интериор (360° виртуална обиколка) и заснемане с дрон.
                                                </p>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="7" ref={sevenRef}>
                                            <div className="accent-container" >
                                                <h2>
                                                    Маркетингови <span>проучвания</span>
                                                </h2>
                                                <p>
                                                    Маркетингови проучвания при сделки с недвижими имоти. Инвестиционни анализи и пазарни проучвания.
                                                </p>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="8" ref={eightRef}>
                                            <div className="accent-container" >
                                                <h2>
                                                    Устройствени <span>процедури</span>
                                                </h2>
                                                <p>
                                                    Устройствени процедури при закупуване на земя процедури при закупуване на земя.
                                                </p>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </Container>
            </article>
            {/* <Footer /> */}
        </>
    );
};

export default ServicesPage;
