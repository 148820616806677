import { useState, useEffect, Fragment } from 'react'
import { useNavigate } from 'react-router'
import { Helmet } from "react-helmet";
import './styles.scss'
import { Data } from '../../data'
import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import areaIcon from '../../assets/icons/area.svg'
import propertyTypeIcon from '../../assets/icons/property-type.svg'
import floorIcon from '../../assets/icons/floor.svg'
import levelOfFurnitureIcon from '../../assets/icons/level-of-furniture.svg'
import heatingIcon from '../../assets/icons/heating.svg'
import constructionIcon from '../../assets/icons/construction.svg'
import BaseButton from '../../components/base-components/BaseButton'
import ProductPrice from '../product-price'
import ProductCardCarousel from '../product-card-carousel'
import ImageGallery from 'react-image-gallery';
import Title from '../title/Title'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SocialIcon from '../../components/base-components/SocialIcon'
import facebook from '../../assets/icons/facebook.svg'
import imotBgLogo from '../../assets/icons/imot-bg.svg'
import realistimoLogo from '../../assets/icons/realistimo.svg'
import ScrollAnimation from 'react-animate-on-scroll'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const renderCards = (products) => {
  return products.reverse().slice(0, 8)
    .map(product => {
      return (
        <Fragment key={product.id}>
          <ProductCardCarousel {...product} />
        </Fragment>
      )
    })
}


const ProductGallery = () => {
  const { id } = useParams();
  const product = Data.filter((item) => {
    return item.id === id
  })[0]

  const importAll = (e) => {
    return e.keys().map(e);
  }

  const idPath = `../../../public/assets/products/id${product.id}/`
  const filenames = importAll(require.context('../../../public/assets/products/id32/', false, /\.(png|jpe?g|svg)$/));

  const [filenameArr, setFilenameArr] = useState([])

  useEffect(() => {
    const galleryArray = [];
    for (let i = 0; i < filenames.length; i++) {
      galleryArray.push({ original: filenames[i], thumbnail: filenames[i] });
    }
    setFilenameArr(galleryArray)
  }, [])


  // console.log(filenameArr)

  return <ImageGallery items={product.gallery} showPlayButton={false} />
}

const ProductMap = ({ src, alt, link }) => {
  return (
    <div className='product-map'>
      <a href={link} target="_blank" rel="noreferrer">
        <img src={src} alt={alt} width="588" height="230" />
      </a>
    </div>
  )
}

const ProductInfo = ({ icon, alt, title, value, unit }) => {
  return (
    <div className='product-info-block'>
      <img src={icon} alt={alt} width="24" height="24" />
      <p><span>{title}:</span> {value} {unit}</p>
    </div>
  )
}

const ProductPage = () => {
  const { id } = useParams();
  const product = Data.filter((item) => {
    return item.id === id
  })[0]

  const history = useNavigate();

  function handleClick() {
    history("/contacts");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const price = Number(product.price.replace(/\s/g, ''))
  const areaPrice = Number(product.area)
  const pricePerMeter = (price / areaPrice).toFixed(2)

  const [filertFilterNewCards, setFilterNewCards] = useState([]);

  useEffect(() => {
    setFilterNewCards(Data.filter(item => item.status === "Продажба" || item.status === "Под наем"))
  }, []);

  const isImotBg = product.linkImotBg.length > 0;

  return (
    <>
      <Helmet>
        <title>Drem Home-19 | {product.title} {product.subtitle}</title>
        <link rel="icon" type="image/x-icon" href="../../assets/images/favicon-32x32.png"></link>
        <link rel="apple-touch-icon" sizes="57x57" href="../assets/favicon/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="../assets/favicon/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="../assets/favicon/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="../assets/favicon/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="../assets/favicon/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="../assets/favicon/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="../assets/favicon/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="../assets/favicon/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="../assets/favicon/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="../assets/favicon/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="../assets/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="../assets/favicon/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="../assets/favicon/favicon-16x16.png" />
        <link rel="manifest" href="../assets/favicon/manifest.json" />
        <link rel="canonical" href="https://dreamhome19.bg/" />
        <meta charSet="utf-8" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="../assets/favicon/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff"></meta>
        <meta name="title" content={product.title} />
        <meta name="description" content={product.subtitle} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0, user-scalable=0" />
        <meta name="keywords" content="Недвижими имоти, продажба, под наем" />
        <meta name="author" content="Drem Home-19" />

        <meta property="og:type" content="metadata"/>
        <link rel="origin" href={product.url}/>
        <meta property="og:title" content={product.title} />
        <meta property="og:description" content={product.subtitle} />
        <meta property="og:image" content={product.image} />
        <meta property="og:url" content={product.url} />
      </Helmet>
      <Container className='product-page'>
        <Row className='product-page-title-container'>
          <Col lg={9} md={7}><h1 className='product-page-title'>{product.title}, <span>{product.subtitle}</span></h1></Col>
          <Col lg={3} md={5} className='price'><ProductPrice price={product.price} /></Col>
        </Row>
        <Row className='product-short-description'>
          <Col md={7} sm={12}><p>Код на имота: <span>{product.id}</span></p></Col>
          <Col md={5} sm={12} className='product-params'>
            <p>{product.subtitle}</p>
            <p><strong>{pricePerMeter}</strong> &euro;/кв.м</p>
            <p><strong>{product.propertyType}</strong></p>
            <p><strong>{product.area}</strong> кв.м</p>
          </Col>
        </Row>
        <Row className='current-product-container'>
          <Col lg={7} md={12} className='first'>
            {/* <ImageList /> */}
            <div className='ribbon'>{product.status}</div>
            <ProductGallery />
          </Col>
          <Col lg={5} md={12}>
            <div className="product-info-container">
              <div className="product-info">
                <h4><span>01. </span>Параметри</h4>
                <div className='information-container'>
                  <ProductInfo icon={propertyTypeIcon} alt='Property type' title='Тип имот' value={product.propertyType} />
                  <ProductInfo icon={floorIcon} alt='Floor' title='Етаж' value={product.floor} />
                  <ProductInfo icon={areaIcon} alt='Area' title='Площ' value={product.area} unit='кв.м' />
                  <ProductInfo icon={levelOfFurnitureIcon} alt='Level of furniture' title='Ниво на обзавеждане' value={product.levelOfFurniture} />
                  <ProductInfo icon={constructionIcon} alt='Construction' title='Стройтелство' value={product.construction} />
                  <ProductInfo icon={heatingIcon} alt='Heating' title='Отопление' value={product.heating} />
                </div>
                <h4><span>02. </span>Описание на имота</h4>
                <p className='mb-4'>{product.propertyInformation}</p>
                <h4><span>03. </span>Местоположение</h4>
                <ProductMap src={product.location} alt={product.title} link={product.locationLink} />
              </div>
              <div className='view-container'>
                <BaseButton title='Заяви оглед' onClick={handleClick} />
                <p>+359 898 724 644</p>
              </div>
              {isImotBg ?
                <SocialIcon icon={imotBgLogo} alt='imot.bg' href={product.linkImotBg} target='_blank' title='Разгледай обявата в' titleBold='imot.bg' />
                :
                <SocialIcon icon={realistimoLogo} alt='realistimo.com' href={product.linkRealistimo} target='_blank' title='Разгледай обявата в' titleBold='realistimo.com' />
              }
              <SocialIcon icon={facebook} alt='facebook' href='https://www.facebook.com/DreamHome19BG' target='_blank' title='Последвайте ни във' titleBold='Facebook' />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Title title1='Разгледай' title2='още предложения' subtitle='' />
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce="true">
              <Carousel responsive={responsive} showDots={true}>
                {renderCards(filertFilterNewCards)}
              </Carousel>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProductPage