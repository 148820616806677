import './styles.scss'
import BaseImage from '../base-components/BaseImage';
import image1 from '../../assets/images/img1.jpg'
import image2 from '../../assets/images/img2.jpg'
import image3 from '../../assets/images/img3.jpg'
import bgImage from '../../assets/images/dashboard-map-bg.png'

const DashboardImage = ({ img, num, alt }) => {
    return (
        <div className={`dashboard-image-container ${num}`}>
            <div className='dashboard-image-wrapper'>
                <BaseImage img={img} alt={alt} width='200' height='200' />
            </div>
        </div>
    )
}

const DashboardImages = () => {
    return (
        <div className='dashboard-images-wrapper'>
            <DashboardImage img={image1} num='first' alt="rentals" />
            <DashboardImage img={image2} num='second' alt="sale" />
            <DashboardImage img={image3} num='third' alt="sell"/>
            <BaseImage img={bgImage} alt='first' width='600' height='600' className="dashboard-bg-image" />
        </div>
    );
};

export default DashboardImages;