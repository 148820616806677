import React from 'react';
import { BrowserRouter   } from "react-router-dom";
import AppRoutes from './routes/Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
// import logo from './logo.svg';
import './App.scss';

// import { getPosts } from './actions/posts';
// import { useDispatch } from 'react-redux'

sessionStorage.setItem('key', 'value')
// let data = sessionStorage.getItem('key')
// sessionStorage.getItem('key')
sessionStorage.removeItem('key')
sessionStorage.clear()

function App() {
  // const [currentId, setCurrentId] = useState(0)
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getPosts());
  // }, [dispatch]);

  return (
    <BrowserRouter>
      <AppRoutes />
      <script src="/build/client.entry.js" />
    </BrowserRouter>
  );
}

export default App;
