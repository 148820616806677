import React, { useState, useEffect } from 'react';
import Mailto from 'react-protected-mailto'
import { useNavigate } from "react-router-dom";
import "./ContactsPage.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BaseButton from "../../components/base-components/BaseButton"
import Main from '../../components/main-title/Main'
import BaseImage from '../../components/base-components/BaseImage'
// import broker from '../../assets/images/broker.jpg'
import email from '../../assets/icons/email.svg'
import email2 from '../../assets/icons/email2.svg'
import phone from '../../assets/icons/phone.svg'
import avatar from '../../assets/images/zoia_broker.jpg'
import SocialIcon from '../../components/base-components/SocialIcon'
import facebook from '../../assets/icons/facebook.svg'
import imotBg from '../../assets/icons/imot-bg.svg'
import ContactForm from '../../components/form/contact-form';

const ContactsPage = () => {

    const [animate, setAnimate] = useState(true);

    useEffect(() => {
        if (window.sessionStorage.getItem("firstLoadContactsDone") === null) {
            setAnimate(true)

            window.sessionStorage.setItem("firstLoadContactsDone", 5)
        } else {
            setAnimate(false)
        }
    }, [animate]);

    const history = useNavigate();

    function handleClick() {
        history("/real-estates");
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    return (
        <>
            <Helmet>
                <title>Drem Home-19 | Контакти</title>
                <link rel="icon" type="image/x-icon" href="../../assets/images/favicon-32x32.png"></link>
                <link rel="apple-touch-icon" sizes="57x57" href="../assets/favicon/apple-icon-57x57.png" />
                <link rel="apple-touch-icon" sizes="60x60" href="../assets/favicon/apple-icon-60x60.png" />
                <link rel="apple-touch-icon" sizes="72x72" href="../assets/favicon/apple-icon-72x72.png" />
                <link rel="apple-touch-icon" sizes="76x76" href="../assets/favicon/apple-icon-76x76.png" />
                <link rel="apple-touch-icon" sizes="114x114" href="../assets/favicon/apple-icon-114x114.png" />
                <link rel="apple-touch-icon" sizes="120x120" href="../assets/favicon/apple-icon-120x120.png" />
                <link rel="apple-touch-icon" sizes="144x144" href="../assets/favicon/apple-icon-144x144.png" />
                <link rel="apple-touch-icon" sizes="152x152" href="../assets/favicon/apple-icon-152x152.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="../assets/favicon/apple-icon-180x180.png" />
                <link rel="icon" type="image/png" sizes="192x192" href="../assets/favicon/android-icon-192x192.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="../assets/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="96x96" href="../assets/favicon/favicon-96x96.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="../assets/favicon/favicon-16x16.png" />
                <link rel="manifest" href="../assets/favicon/manifest.json" />
                <link rel="canonical" href="https://dreamhome19.bg/" />
                <meta charSet="utf-8" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="../assets/favicon/ms-icon-144x144.png" />
                <meta name="theme-color" content="#ffffff"></meta>
                <meta name="description"
                    content="Намерете услуги за търговски недвижими имоти и стратегии за инвестиции в имоти. Работим със собственици, наематели и инвеститори на местните и национални пазари." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0, user-scalable=0" />
                <meta name="keywords" content="Недвижими имоти, продажба, под наем" />
                <meta name="author" content="Drem Home-19" />
                <meta property="og:title" content="Drem Home-19 | Контакти" />
                <meta property="og:description"
                    content="Намерете услуги за търговски недвижими имоти и стратегии за инвестиции в имоти. Работим със собственици, наематели и инвеститори на местните и национални пазари." />
                <meta property="og:image" content="../../assets/logo/logoDreamHome19.svg" />
                <meta name="prerender-status-code" content="404"></meta>
            </Helmet>

            <main className='contacts-main'>
                <Container fluid>
                    <Row className={animate ? "animate" : ""}>
                        <Main title1='КОНТАКТИ' title2='' subtitle='Нека обсъдим вашият нов имот' button='Намери' className='contacts' />
                    </Row>
                </Container>
            </main>
            <article className="contacts-page-container fade-in">
                <Container>
                    <Row className='d-flex justify-content-center'>
                        {/* <Col sm={12} md={6}>
                            <BaseImage img={broker} alt="Зоя Манолова" width="604" height="850"/>
                        </Col> */}
                        <Col sm={12} md={6} xl={7} className='contacts-context'>
                            <div className='avatar-container'>
                                <BaseImage img={avatar} width="85px" height="66px" />
                                <div>
                                    <h2>Зоя Манолова</h2>
                                    <span>Изпълнителен директор</span>
                                </div>
                            </div>
                            <div className='person-info-container'>
                                <div className='person-info-element'>
                                    <img src={phone} alt='phone' /> <Mailto tel='+359898724644' /><br />
                                </div>
                                <div className='person-info-element'>
                                    <img src={email} alt='' />
                                    <Mailto
                                        email='zoiamanolova@gmail.com'
                                        headers={
                                            { subject: 'Свържете се с нас' },
                                            { cc: 'zoiamanolova@gmail.com' }
                                        }
                                    />
                                </div>
                                <div className='person-info-element'>
                                    <img src={email2} alt='office' />
                                    <Mailto
                                        email='office@dreamhome19.bg'
                                        headers={
                                            { subject: 'Свържете се с нас' },
                                            { cc: 'office@dreamhome19.bg' }
                                        }
                                    />
                                </div>
                            </div>
                            <p>ТОП брокер съм на водеща агенция от 2019 г. В сферата на търговията работя от 2005 г. Участвам в различни обучителни програми, в непрекъснато надграждане и новаторство в бизнеса с недвижимите имоти.
                                Отговорността, коректността, комуникативните умения и позитивното отношение винаги са ми помагали в най-големите предизвикателства.</p>
                            <BaseButton title="Нека обсъдим вашият нов имот" onClick={handleClick}></BaseButton>
                            <SocialIcon icon={facebook} alt='facebook' href='https://www.facebook.com/DreamHome19BG' target='_blank' title='Последвайте ни във' titleBold='Facebook' />
                            <SocialIcon icon={imotBg} alt='imot.bg' href='https://dreamhome19.imot.bg/' target='_blank' title='Разгледай нашите обяви в' titleBold='imot.bg' />
                        </Col>
                        <Col sm={12} md={6} xl={5}>
                            <ContactForm />
                        </Col>
                    </Row>
                </Container>
            </article>
        </>
    );
};

export default ContactsPage;
