import { useState } from 'react';
import Select from 'react-select';
import './styles.scss'
import BaseButton from '../base-components/BaseButton';
import research from '../../assets/icons/new-search.svg'
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Data } from '../../data'

const FilterSelect = ({ setFilterByType, setFilterByBlock, setFilterByНeighborhood, onClick, counter, searchParam }) => {
    const [isActive, setActive] = useState("false");

    const [selectedCityOption, setSelectedCityOption] = useState(null);
    const [selectedBlockOption, setSelectedBlockOption] = useState(null);
    const [selectedTypeOption, setSelectedTypeOption] = useState(null);

    const clearFilter = e => {
        setSelectedCityOption(null)
        setSelectedBlockOption(null)
        setSelectedTypeOption(null)
        setActive(!isActive)
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Ново търсене
        </Tooltip>
    );

    setFilterByType(selectedCityOption?.value)
    setFilterByBlock(selectedBlockOption?.value)
    setFilterByНeighborhood(selectedTypeOption?.value)

    const salesData = Data.filter(item => item.status === searchParam)

    //01. "Населено място" options
    const placesForSaleData = salesData
        .reverse()
        .map(item => item.place)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => a.localeCompare(b))

    const placeOptions = [];
    for (let i = 0; i < placesForSaleData.length; i++) {
        placeOptions.push({ label: placesForSaleData[i], value: placesForSaleData[i] });
    }

    //02. "Квартал" options
    const residentialАreaSaleData = salesData
        .filter(item => item.residentialАrea && item.place === selectedCityOption?.value)
        .map(item => item.residentialАrea)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => a.localeCompare(b))

    const areaOptions = [];
    for (let e = 0; e < residentialАreaSaleData.length; e++) {
        areaOptions.push({ label: residentialАreaSaleData[e], value: residentialАreaSaleData[e] });
    }

    //03. "Тип имот" options
    const typeSaleData = salesData
        .filter(item => item.residentialАrea && item.residentialАrea === selectedBlockOption?.value)
        .map(item => item.propertyType)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => a.localeCompare(b))

    const typeOptions = [];
    for (let y = 0; y < typeSaleData.length; y++) {
        typeOptions.push({ label: typeSaleData[y], value: typeSaleData[y] });
    }

    return (
        <>
            <div className='dropdown'>
                <Select
                    value={selectedCityOption}
                    onChange={(selectedCityOption) =>
                        setSelectedCityOption(selectedCityOption)}
                    options={placeOptions}
                    placeholder="Населено място"
                    className='dropdown-select'
                    classNamePrefix='dropdown-select'
                    isClearable
                    isSearchable={false}
                    noOptionsMessage={() => "Няма намерени"}
                />
                <Select
                    value={selectedBlockOption}
                    onChange={(selectedBlockOption) =>
                        setSelectedBlockOption(selectedBlockOption)}
                    options={areaOptions}
                    placeholder="Квартал"
                    className='dropdown-select'
                    classNamePrefix='dropdown-select'
                    isClearable
                    isSearchable={false}
                    noOptionsMessage={() => "Избери Населено място"}
                />
                <Select
                    value={selectedTypeOption}
                    onChange={(selectedTypeOption) =>
                        setSelectedTypeOption(selectedTypeOption)}
                    options={typeOptions}
                    placeholder="Тип имот"
                    className='dropdown-select'
                    classNamePrefix='dropdown-select'
                    isClearable
                    isSearchable={false}
                    noOptionsMessage={() => "Избери Квартал"}
                />
            </div>

            <div className='button-wrapper'>
                <BaseButton title='Намери' className="no-arrow" specific={counter} onClick={onClick} />
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 150 }}
                    overlay={renderTooltip}
                >
                    <div className='clear-wrapper' >
                        <BaseButton icon={research}
                            imgWidth="23"
                            imgHeight="23"
                            onClick={clearFilter}
                            alt="Clear search"
                            className={`no-arrow clear ${isActive ? "active" : ""}`}
                        />
                    </div>
                </OverlayTrigger>
            </div>
        </>
    );
};

export default FilterSelect;
