import { Fragment } from 'react';
import './BaseButton.scss';

const BaseButton = ({ title, type, specific, icon, alt, onClick, className, form, showSpinner, disabled, onMouseEnter, onMouseLeave, imgWidth, imgHeight }) => {
    return (
        <Fragment>
            <button
                type={type}
                className={`button ${className ? className : "" || showSpinner ? "disabled" : ""}`}
                onClick={onClick} form={form} disabled={disabled}
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}>
                {icon && <img src={icon} alt={alt} width={imgWidth} height={imgHeight}></img>}
                <span className='specific'>{specific}</span>{title}
                <div className="long-arrow-right"></div>
            </button>
        </Fragment>
    )
}

export default BaseButton;