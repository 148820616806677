import './styles.scss'

const ProductPrice = ({ price }) => {

  return (
    <div className="product-price">
      <h5>Цена:</h5>
      <p>&#8364; <span>{price}</span></p>
    </div>
  )
}

export default ProductPrice