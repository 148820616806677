import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Data } from '../../data'
import ProductPrice from '../product-price'
import './styles.scss'
import { Link } from 'react-router-dom'
import Title from '../title/Title'
import areaIcon from '../../assets/icons/area.svg'
import propertyTypeIcon from '../../assets/icons/property-type.svg'
import floorIcon from '../../assets/icons/floor.svg'
import levelOfFurnitureIcon from '../../assets/icons/level-of-furniture.svg'
import heatingIcon from '../../assets/icons/heating.svg'
import constructionIcon from '../../assets/icons/construction.svg'
import BaseButton from '../../components/base-components/BaseButton'
import SocialIcon from '../../components/base-components/SocialIcon'
import facebook from '../../assets/icons/facebook.svg'
import imotBgLogo from '../../assets/icons/imot-bg.svg'
import realistimoLogo from '../../assets/icons/realistimo.svg'
import ImageGallery from 'react-image-gallery';


const ProductInfo = ({ icon, alt, title, value, unit }) => {
  return (
    <div className='product-info-block'>
      <img src={icon} alt={alt} />
      <p><span>{title}:</span> {value} {unit}</p>
    </div>
  )
}

// const ProductImage = ({ image, title }) => {
//   return (
//     <div className='product-image'>
//       <img src={image} alt={title} />
//     </div>
//   )
// }

const LatestProductCard = ({ image, title, subtitle, status, price, area, propertyInformation, propertyType, floor, levelOfFurniture, heating, construction, id, linkImotBg, linkRealistimo, gallery }) => {

  const { _id } = useParams();
  const productId = Data.filter((item) => {
    return item._id === _id
  })[0]

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Row className='latest-product-card'>
      <Col md={12}>
        <Title title1='Най-новото' title2='предложение' subtitle='' />
      </Col>
      <Col md={12} lg={7}>
        <div className='product-image-link' >
          <div className='ribbon'>{status}</div>
          <ImageGallery items={gallery} showPlayButton={false} />
          {/* <div className='product-id'>
            <p>Kод: <span>{id}</span></p>
          </div> */}
        </div>
      </Col>
      <Col md={12} lg={5} >
        <div className="product-info-container">
          <div className='product-info-title'>
            <h2 className="product-title">{title}&#44;&nbsp;<span>{subtitle}</span></h2>
            <p>{propertyInformation}</p>
          </div>
          <div className="product-info">
            <h5>ОПИСАНИЕ:</h5>
            <div className='information-container'>
              <ProductInfo icon={propertyTypeIcon} alt='Property type' title='Тип имот' value={propertyType} />
              <ProductInfo icon={floorIcon} alt='Floor' title='Етаж' value={floor} />
              <ProductInfo icon={areaIcon} alt='Area' title='Площ' value={area} unit='кв.м' />
              <ProductInfo icon={levelOfFurnitureIcon} alt='Level of furniture' title='Ниво на обзавеждане' value={levelOfFurniture} />
              <ProductInfo icon={constructionIcon} alt='Construction' title='Стройтелство' value={construction} />
              <ProductInfo icon={heatingIcon} alt='Heating' title='Отопление' value={heating} />
            </div>
            <div className='product-price-wrapper'>
              <p><span>{area}</span> кв.м</p>
              <ProductPrice price={price} />
            </div>
            <Link to={`/product/${id}`} onClick={scrollToTop}>
              <BaseButton title='Виж офертата' />
            </Link>
            <SocialIcon icon={facebook} alt='facebook' href='https://www.facebook.com/DreamHome19BG' target='_blank' title='Последвайте ни във' titleBold='Facebook' />
            {linkImotBg.length > 0 ?
              <SocialIcon icon={imotBgLogo} alt='imot.bg' href={linkImotBg} target='_blank' title='Разгледай обявата в' titleBold='imot.bg' />
              :
              <SocialIcon icon={realistimoLogo} alt='realistimo.com' href={linkRealistimo} target='_blank' title='Разгледай обявата в' titleBold='realistimo.com' />
            }
          </div>
        </div>
      </Col>

    </Row>
  )
}

LatestProductCard.defaultProps = {
  image: '',
  title: 'Placeholder',
  price: '0'
}

LatestProductCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired
}

export default LatestProductCard