import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom"
import "./ProductListPage.scss"
import { Container, Row, Col, Tab, Nav } from "react-bootstrap"
import { Helmet } from "react-helmet";
import Main from '../../components/main-title/Main'
import BaseButton from '../../components/base-components/BaseButton'
import BaseImage from '../../components/base-components/BaseImage'
import ProductCard from '../../components/product-card'
import home from '../../assets/icons/sale.svg'
import ScrollAnimation from 'react-animate-on-scroll'
import searchProductImg from '../../assets/icons/product-search.svg'
import { Data } from '../../data'
import FilterSelect from '../../components/filterSelect';

const ProductListPage = () => {
    const [animate, setAnimate] = useState(true);

    // Sales
    const [products, setProducts] = useState(Data.filter(item => item.status === "Продажба" || item.status === "Продадено"));
    const [sortedProducts, setSortedProducts] = useState([]);

    const [searchValueSales, setsearchValueSales] = useState([]);
    const [filterByType, setFilterByType] = useState([]);
    const [filterByBlock, setFilterByBlock] = useState([]);
    const [filterByНeighborhood, setFilterByНeighborhood] = useState([]);

    //Rents
    const [rents, setRents] = useState(Data.filter(item => item.status === "Под наем" || item.status === "Отдадено"));
    const [sortedRents, setSortedRents] = useState([]);

    const [searchValueSalesRents, setsearchValueSalesRents] = useState("");
    const [filterByTypeRents, setFilterByTypeRents] = useState("");
    const [filterByBlockRents, setFilterByBlockRents] = useState("");
    const [filterByНeighborhoodRents, setFilterByНeighborhoodRents] = useState("");

    const history = useNavigate();
    function handleClick() {
        history("/contacts");
    }

    function handleToNewSearch() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    const salesRef = useRef()
    const rentsRef = useRef()

    const handleSalesClick = () => {
        salesRef.current.scrollIntoView({ behavior: 'smooth' })
        setProducts(Data.filter(item => item.status === "Продажба"));
    }

    const handleRentsClick = () => {
        rentsRef.current.scrollIntoView({ behavior: 'smooth' })
        setRents(Data.filter(item => item.status === "Под наем"));
    }

    useEffect(() => {
        if (window.sessionStorage.getItem("firstLoadProductListDone") === null) {
            setAnimate(true)

            window.sessionStorage.setItem("firstLoadProductListDone", 2)
        } else {
            setAnimate(false)
        }
    }, [animate]);

    useEffect(() => {
        const searchRegex = searchValueSales && new RegExp(`${searchValueSales}`, "gi");
        const blockRegex = filterByBlock && new RegExp(`${filterByBlock}`, "gi");
        const heighborhoodRegex = filterByНeighborhood && new RegExp(`${filterByНeighborhood}`, "gi");

        const result = products.filter(
            product =>
                (!filterByType || product.place === filterByType) &&
                (!searchRegex || searchRegex.test(product.title)) &&
                (!blockRegex || blockRegex.test(product.residentialАrea)) &&
                (!heighborhoodRegex || heighborhoodRegex.test(product.propertyType))
        );
        setSortedProducts(result.reverse());
    }, [products, searchValueSales, filterByBlock, filterByНeighborhood, filterByType]);


    useEffect(() => {
        const searchRegex = searchValueSalesRents && new RegExp(`${searchValueSalesRents}`, "gi");
        const yearRegex = filterByBlockRents && new RegExp(`${filterByBlockRents}`, "gi");
        const heighborhoodRegex = filterByНeighborhoodRents && new RegExp(`${filterByНeighborhoodRents}`, "gi");

        const result = rents.filter(
            product =>
                (!searchRegex || searchRegex.test(product.title)) &&
                (!filterByTypeRents || product.place === filterByTypeRents) &&
                (!yearRegex || yearRegex.test(product.residentialАrea)) &&
                (!heighborhoodRegex || heighborhoodRegex.test(product.propertyType))
        );
        setSortedRents(result);
    }, [searchValueSalesRents, rents, filterByTypeRents, filterByBlockRents, filterByНeighborhoodRents]);

    return (
        <>
            <Helmet>
                <title>Drem Home-19 | Оферти</title>
                <link rel="icon" type="image/x-icon" href="../../assets/images/favicon-32x32.png"></link>
                <link rel="apple-touch-icon" sizes="57x57" href="../assets/favicon/apple-icon-57x57.png" />
                <link rel="apple-touch-icon" sizes="60x60" href="../assets/favicon/apple-icon-60x60.png" />
                <link rel="apple-touch-icon" sizes="72x72" href="../assets/favicon/apple-icon-72x72.png" />
                <link rel="apple-touch-icon" sizes="76x76" href="../assets/favicon/apple-icon-76x76.png" />
                <link rel="apple-touch-icon" sizes="114x114" href="../assets/favicon/apple-icon-114x114.png" />
                <link rel="apple-touch-icon" sizes="120x120" href="../assets/favicon/apple-icon-120x120.png" />
                <link rel="apple-touch-icon" sizes="144x144" href="../assets/favicon/apple-icon-144x144.png" />
                <link rel="apple-touch-icon" sizes="152x152" href="../assets/favicon/apple-icon-152x152.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="../assets/favicon/apple-icon-180x180.png" />
                <link rel="icon" type="image/png" sizes="192x192" href="../assets/favicon/android-icon-192x192.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="../assets/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="96x96" href="../assets/favicon/favicon-96x96.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="../assets/favicon/favicon-16x16.png" />
                <link rel="manifest" href="../assets/favicon/manifest.json" />
                <link rel="canonical" href="https://dreamhome19.bg/" />
                <meta charSet="utf-8" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="../assets/favicon/ms-icon-144x144.png" />
                <meta name="theme-color" content="#ffffff"></meta>
                <meta name="description"
                    content="Намерете услуги за търговски недвижими имоти и стратегии за инвестиции в имоти. Работим със собственици, наематели и инвеститори на местните и национални пазари." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0, user-scalable=0" />
                <meta name="keywords" content="Недвижими имоти, продажба, под наем" />
                <meta name="author" content="Drem Home-19" />
                <meta property="og:title" content="Drem Home-19 | Оферта" />
                <meta property="og:description"
                    content="Намерете услуги за търговски недвижими имоти и стратегии за инвестиции в имоти. Работим със собственици, наематели и инвеститори на местните и национални пазари." />
                <meta property="og:image" content="../../assets/logo/logoDreamHome19.svg" />
                <meta name="prerender-status-code" content="404"></meta>
            </Helmet>

            <main className='product-list-main'>
                <Container fluid>
                    <Row className={animate ? "animate" : ""}>
                        <Main title1='ОТКРИЙТЕ' title2='СВОЯ ДОМ С DREAM HOME' subtitle='Oткрийте мечтаното жилище при нас' button='Намери' className='estates' />
                    </Row>
                    <Row className='tabs-container'>
                        <div className="tabs-wrapper">
                            <Tab.Container id="uncontrolled-tab" defaultActiveKey="sales" transition={true} >
                                <Nav variant="pills" className="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="sales">Купи</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="rents">Наеми</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="sell">Продай</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content className="content">
                                    <Tab.Pane eventKey="sales">
                                        <div className="accent-container tab">
                                            <FilterSelect
                                                searchParam="Продажба"
                                                setsearchValueSales={setsearchValueSales}
                                                setFilterByType={setFilterByType}
                                                setFilterByBlock={setFilterByBlock}
                                                setFilterByНeighborhood={setFilterByНeighborhood}
                                                setTab="Продажба"
                                                counter={sortedProducts.length}
                                                onClick={handleSalesClick}
                                            />
                                        </div>

                                        <article className="product-list-page-container fade-in">
                                            <Container>
                                                <Row>
                                                    <Col ref={salesRef}>
                                                        <h2><span>01.</span> За продажба</h2>
                                                        <ScrollAnimation animateIn="animate__fadeIn">
                                                            <section className='product-list'>
                                                                {sortedProducts?.length > 0 ? (
                                                                    sortedProducts.map((product, index) => {
                                                                        return <ProductCard key={index} {...product} />
                                                                    })
                                                                ) : (
                                                                    <span className='no-products'>
                                                                        <BaseImage img={searchProductImg}
                                                                            className="search-icon"
                                                                            width='35'
                                                                            height='35'
                                                                            alt='No product found'
                                                                        />
                                                                        Няма намерени оферти за продажба
                                                                    </span>
                                                                )}
                                                            </section>
                                                        </ScrollAnimation>
                                                    </Col>
                                                </Row>
                                                <Row className='go-to-search'>
                                                    <ScrollAnimation animateIn="animate__fadeIn">
                                                        <BaseButton title='Ново търсене' onClick={handleToNewSearch} />
                                                    </ScrollAnimation>
                                                </Row>
                                            </Container>
                                        </article>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="rents">
                                        <div className="accent-container tab">
                                            <FilterSelect
                                                searchParam="Под наем"
                                                setsearchValueSales={setsearchValueSalesRents}
                                                setFilterByType={setFilterByTypeRents}
                                                setFilterByBlock={setFilterByBlockRents}
                                                setFilterByНeighborhood={setFilterByНeighborhoodRents}
                                                setTab="Под наем"
                                                counter={sortedRents.length}
                                                onClick={handleRentsClick}
                                            />
                                        </div>
                                        <article className="product-list-page-container fade-in">
                                            <Container>
                                                <Row>
                                                    <Col ref={rentsRef}>
                                                        <h2><span>02.</span> Под наем</h2>
                                                        <ScrollAnimation animateIn="animate__fadeIn">
                                                            <section className='product-list'>
                                                                {sortedRents?.length > 0 ? (
                                                                    sortedRents.reverse()
                                                                        .map((product, index) => {
                                                                            return <ProductCard key={index} {...product} />;
                                                                        })
                                                                ) : (
                                                                    <span className='no-products'>
                                                                        <BaseImage img={searchProductImg}
                                                                            className="search-icon"
                                                                            width='35'
                                                                            height='35'
                                                                            alt='No product found'
                                                                        />
                                                                        Няма намерени оферти за наем
                                                                    </span>
                                                                )}
                                                            </section>
                                                        </ScrollAnimation>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </article>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="sell">
                                        <div className="accent-container tab sale">
                                            <BaseImage img={home} alt='Home' width="50px" height="50px" />
                                            <p className='mb-5'>
                                                Продай имота си <strong>най-изгодно</strong> и <strong>бързо</strong>, с нашата помощ.
                                            </p>
                                            <BaseButton title='Продай имота си' onClick={handleClick} />
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Row>
                </Container>
            </main>
        </>
    );
};

export default ProductListPage;
