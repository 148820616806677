import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import TopNavbar from "../components/navbar/TopNavbar";
import ErrorPage from '../pages/error-page/ErrorPage'
import Dashboard from '../pages/dashboard/Dashboard'
import Footer from "../components/footer/Footer";
import ProductPage from '../components/product-page'
import ProductListPage from '../pages/product-list/ProductListPage'
import ServicesPage from '../pages/services/ServicesPage'
import AboutUsPage from '../pages/about-us/AboutUsPage'
import ContactsPage from '../pages/contacts/ContactsPage'
import ServicesPreview from '../components/services-preview/ServicesPreview'
// import Admin from '../pages/admin/Admin'

export const RemoveTrailingSlash = ({ ...rest }) => {
    // const location = useLocation()

    // If the last character of the url is '/'
    // if (location.pathname.match('/.*/$')) {
    //     return <Navigate replace {...rest} to={{
    //         pathname: location.pathname.replace(/\/+$/, ""),
    //         search: location.search
    //     }} />
    // } else return null
}

function AppRoutes() {
    const location = useLocation();

    return (
        <div>
            {location.pathname !== '*' && <TopNavbar />}
            <Helmet>
                <title>Dream Home-19 | Real Estate agency</title>
                <link rel="icon" type="image/x-icon" href="../../assets/images/favicon-32x32.png"></link>
                <link rel="apple-touch-icon" sizes="57x57" href="../assets/favicon/apple-icon-57x57.png" />
                <link rel="apple-touch-icon" sizes="60x60" href="../assets/favicon/apple-icon-60x60.png" />
                <link rel="apple-touch-icon" sizes="72x72" href="../assets/favicon/apple-icon-72x72.png" />
                <link rel="apple-touch-icon" sizes="76x76" href="../assets/favicon/apple-icon-76x76.png" />
                <link rel="apple-touch-icon" sizes="114x114" href="../assets/favicon/apple-icon-114x114.png" />
                <link rel="apple-touch-icon" sizes="120x120" href="../assets/favicon/apple-icon-120x120.png" />
                <link rel="apple-touch-icon" sizes="144x144" href="../assets/favicon/apple-icon-144x144.png" />
                <link rel="apple-touch-icon" sizes="152x152" href="../assets/favicon/apple-icon-152x152.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="../assets/favicon/apple-icon-180x180.png" />
                <link rel="icon" type="image/png" sizes="192x192" href="../assets/favicon/android-icon-192x192.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="../assets/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="96x96" href="../assets/favicon/favicon-96x96.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="../assets/favicon/favicon-16x16.png" />
                <link rel="manifest" href="../assets/favicon/manifest.json" />
                <link rel="canonical" href="https://dreamhome19.bg/" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="../assets/favicon/ms-icon-144x144.png" />
                <meta name="theme-color" content="#ffffff"></meta>
                <meta name="description"
                    content="Намерете услуги за търговски недвижими имоти и стратегии за инвестиции в имоти. Работим със собственици, наематели и инвеститори на местните и национални пазари." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0, user-scalable=0" />
                <meta name="keywords" content="" />
                <meta name="author" content="Dream Home-19" />
                <meta property="og:title" content="Dream Home-19 | Real Estate agency" />
                <meta property="og:description"
                    content="Намерете услуги за търговски недвижими имоти и стратегии за инвестиции в имоти. Работим със собственици, наематели и инвеститори на местните и национални пазари." />
                <meta property="og:image" content="../../assets/images/share/share1.png" />
            </Helmet>
            {/* <RemoveTrailingSlash /> */}
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/product/:id/" element={<ProductPage />} />
                <Route path="/real-estates" element={<ProductListPage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/contacts" element={<ContactsPage />} />
                <Route path="/404" element={<ErrorPage />} />
                <Route path="*"
                    element={<Navigate to="/404" replace />} />
            </Routes>
            {location.pathname !== '/services' && <ServicesPreview />}
            <Footer />
        </div>
    )
}

export default AppRoutes;
