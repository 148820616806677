import './ServicesPreview.scss'
import { Col, Container, Row } from 'react-bootstrap'
import BaseButton from '../base-components/BaseButton';
import { Link } from 'react-router-dom';
import BaseImage from '../base-components/BaseImage';
import service1 from '../../assets/icons/service1.svg';
import service2 from '../../assets/icons/service2.svg';
import service3 from '../../assets/icons/service3.svg';
import service4 from '../../assets/icons/service4.svg';
import ScrollAnimation from 'react-animate-on-scroll'


const ServiceBlock = ({ icon, title1, title2, subtitle }) => {
  return (
    <Col sm={12} md={6} xl={3} className='service-block'>
      <ScrollAnimation animateIn="animate__fadeIn">
        <BaseImage img={icon} width="65px" height="65px"/>
        <h5><span>{title1}</span> {title2}</h5>
        <p>{subtitle}</p>
      </ScrollAnimation>
    </Col>
  )
}

const ServicesPreview = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container fluid className="services-preview-container">
      <Row >
        <Col >
          <div className='preview-title-container'>
            <h3>Защо да изберете нас за<span> Ваш</span> брокер</h3>
            <p>Oткрийте мечтаното жилище при нас</p>
          </div>
        </Col>
      </Row>
      <Row>
        <ServiceBlock icon={service1}
          title1='Професионално консултиране'
          title2=' в сферата на имотите'
          subtitle='Професионално консултиране в сферата на имотите и предоставяне на коректно обслужване, гласувано доверие в желанието за успех.'
        />
        <ServiceBlock icon={service2}
          title1='Експертни оценки и консултации'
          title2=' за пазарна стойност'
          subtitle='Експертни оценки и консултации за пазарна стойност на недвижими имоти '
        />
        <ServiceBlock icon={service3}
          title1='Правно и техническо обслужване'
          title2=' на сделки с имоти'
          subtitle='Правно и техническо обслужване на сделки с недвижими имоти.'
        />
        <ServiceBlock icon={service4}
          title1='Професионално фотографско'
          title2=' заснемане на имоти'
          subtitle='Професионално фотографско заснемане на имоти, пространствено заснемане на интериор (360° виртуална обиколка) и заснемане с дрон.'
        />
      </Row>
      <Row>
        <Col className='d-flex justify-content-center'>
          <Link to={'/services'}>
            <BaseButton title='Научете повече' onClick={scrollToTop} />
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default ServicesPreview