import './Main.scss'
import { Col } from 'react-bootstrap'
import element from '../../assets/logo/logo-element.svg'

const MainTitle = ({ title1, title2, subtitle, button, className }) => {
  return (
    <>
      <Col className={`main-container ${className}`} >
        <div className="main-title-container">
          <h1><div>{title1}</div><div><span> {title2}</span></div></h1>
          <p>{subtitle}</p>
          <img src={element} alt='Logo element'></img>
        </div>
      </Col>
    </>
  )
}

export default MainTitle