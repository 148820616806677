import React, { useState, useEffect, useRef } from 'react'
import './Dashboard.scss'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Mailto from 'react-protected-mailto'
import BaseButton from '../../components/base-components/BaseButton'
import NewOffers from '../../components/new-offers'
import Main from '../../components/main-title/Main'
import bg from '../../assets/images/dashboard-background.jpg'
import bgHover from '../../assets/images/dashboard-background-hover.svg'
import ScrollAnimation from 'react-animate-on-scroll'
import Counter from '../../components/counter'
import SocialIcon from '../../components/base-components/SocialIcon'
import facebook from '../../assets/icons/facebook.svg'
import imotBg from '../../assets/icons/imot-bg.svg'
import realistimoLogo from '../../assets/icons/realistimo.svg'
import { Data } from '../../data'
import DashboardImage from '../../components/dashboard-image'

const Dashboard = () => {
    const [animate, setAnimate] = useState(true);
    const [hovered, setHovered] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    // document.body.classList.add('stop');

    const handleScroll = () => {
        const position = window.pageYOffset;
        if (window.innerWidth > 992) {

            setScrollPosition(position);
        }
        else {
            setScrollPosition(0);

        }
    };

    const titleRef = useRef()

    const toggleHover = () => {
        if (window.innerWidth > 1799) {

            setHovered(!hovered)
        }
    };

    const handleBackClick = () => {
        titleRef.current.scrollIntoView({ behavior: 'smooth' })
        // document.body.classList.remove('stop')
    }

    useEffect(() => {
        if (window.sessionStorage.getItem("firstLoadDashboardDone") === null) {
            setAnimate(true)

            window.sessionStorage.setItem("firstLoadDashboardDone", 1)
        } else {
            setAnimate(false)
        }
    }, [animate]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    let salesNum = Number(Data.filter(x => x.status === "Продажба").length)
    let rentNum = Number(Data.filter(x => x.status === "Под наем").length)

    return (
        <>
            <Helmet>
                <title>Drem Home-19 | Агенция за недвижими имоти</title>
                <link rel="icon" type="image/x-icon" href="../../assets/images/favicon-32x32.png"></link>
                <link rel="apple-touch-icon" sizes="57x57" href="../assets/favicon/apple-icon-57x57.png" />
                <link rel="apple-touch-icon" sizes="60x60" href="../assets/favicon/apple-icon-60x60.png" />
                <link rel="apple-touch-icon" sizes="72x72" href="../assets/favicon/apple-icon-72x72.png" />
                <link rel="apple-touch-icon" sizes="76x76" href="../assets/favicon/apple-icon-76x76.png" />
                <link rel="apple-touch-icon" sizes="114x114" href="../assets/favicon/apple-icon-114x114.png" />
                <link rel="apple-touch-icon" sizes="120x120" href="../assets/favicon/apple-icon-120x120.png" />
                <link rel="apple-touch-icon" sizes="144x144" href="../assets/favicon/apple-icon-144x144.png" />
                <link rel="apple-touch-icon" sizes="152x152" href="../assets/favicon/apple-icon-152x152.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="../assets/favicon/apple-icon-180x180.png" />
                <link rel="icon" type="image/png" sizes="192x192" href="../assets/favicon/android-icon-192x192.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="../assets/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="96x96" href="../assets/favicon/favicon-96x96.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="../assets/favicon/favicon-16x16.png" />
                <link rel="manifest" href="../assets/favicon/manifest.json" />
                <link rel="canonical" href="https://dreamhome19.bg/" />
                <meta charSet="utf-8" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="../assets/favicon/ms-icon-144x144.png" />
                <meta name="theme-color" content="#ffffff"></meta>
                <meta name="description"
                    content="Намерете услуги за търговски недвижими имоти и стратегии за инвестиции в имоти. Работим със собственици, наематели и инвеститори на местните и национални пазари." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0, user-scalable=0" />
                <meta name="keywords" content="Недвижими имоти, продажба, под наем" />
                <meta name="author" content="Drem Home-19" />
                <meta property="og:title" content="Drem Home-19 | Агенция за недвижими имоти" />
                <meta property="og:description"
                    content="Намерете услуги за търговски недвижими имоти и стратегии за инвестиции в имоти. Работим със собственици, наематели и инвеститори на местните и национални пазари." />
                <meta property="og:image" content="../../assets/logo/logoDreamHome19.svg" />
                <meta name="prerender-status-code" content="404"></meta>
            </Helmet>
            <main className='dashboard-main'>
                <Container fluid className='dashboard-box'>
                    <Row className={animate ? "animate" : ""}>
                        <Main title1='НАМЕРЕТЕ ПЪТЯ КЪМ ' title2='ДОМА' subtitle='Oткрийте мечтаното жилище с нас' className='home' />
                        <Col className='main-elements'>
                            <div className='element'></div>
                            <div className='counters-container'>
                                <div className='counters-wrapper'>
                                    <Counter Title={"ЗА ПРОДАЖБА"} Value={salesNum} />
                                    <Counter Title={"ПОД НАЕМ"} Value={rentNum} />
                                </div>
                                <BaseButton title='Намери'
                                    className={"counter-button"}
                                    onClick={handleBackClick}
                                    onMouseEnter={toggleHover}
                                    onMouseLeave={toggleHover}
                                />
                            </div>
                            <div className='contact-container'>
                                <div className='contact-name'>
                                    <span>Зоя Манолова</span>
                                    <Mailto tel='+359898724644' />
                                </div>
                                <div className='social-icons'>
                                    <SocialIcon icon={facebook} alt='facebook' href='https://www.facebook.com/DreamHome19BG' target='_blank' />
                                    <SocialIcon icon={realistimoLogo} alt='imot.bg' href={'https://realistimo.com/bg/agency/12315/'} target='_blank' />
                                    <SocialIcon icon={imotBg} alt='imot.bg' href={'https://dreamhome19.imot.bg/'} target='_blank' />
                                </div>
                            </div>
                            <DashboardImage />
                        </Col>
                    </Row>
                </Container>
                <div className={hovered ? 'dashboard-background show' : 'dashboard-background'}>
                    <img src={bg} alt='Background' width='1221' height="1759" className='main-image' style={{ transform: `scale(${(scrollPosition / 50) + 100}%) translateY(${-(scrollPosition) - 50}px)` }}></img>
                    <img src={bgHover} alt='Background hover' width='1221' height="1759" className='hover-image' style={{ transform: `scale(${(scrollPosition / 50) + 100}%) translateY(${-(scrollPosition) - 50}px)` }}></img>
                </div>
            </main>

            <article className='dashboard-container fade-in'>
                <Container>
                    <Row >
                        <Col md={12} ref={titleRef}>
                            <ScrollAnimation animateIn="animate__fadeIn" animateOnce="true" offset="20" >
                                <NewOffers />
                            </ScrollAnimation>

                        </Col>
                        <Col md={12} className='go-to-search'>
                            <ScrollAnimation animateIn="animate__fadeIn" animateOnce="true" offset="20">
                                <Link to={'/real-estates'}>
                                    <BaseButton title='Разгледайте всички оферти' onClick={scrollToTop} />
                                </Link>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </article>
        </>
    )
}

export default Dashboard;

