import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import "./AboutUsPage.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BaseButton from "../../components/base-components/BaseButton";
import Main from '../../components/main-title/Main'

const AboutUsPage = () => {
    const [animate, setAnimate] = useState(true);

    useEffect(() => {
        if (window.sessionStorage.getItem("firstLoadAboutUsDone") === null) {
            setAnimate(true)

            window.sessionStorage.setItem("firstLoadAboutUsDone", 4)
        } else {
            setAnimate(false)
        }
    }, [animate]);

    const history = useNavigate();
    function handleClick() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        history("/real-estates");
    }
    return (
        <>
            <Helmet>
                <title>Drem Home-19 | За нас</title>
                <link rel="icon" type="image/x-icon" href="../../assets/images/favicon-32x32.png"></link>
                <link rel="apple-touch-icon" sizes="57x57" href="../assets/favicon/apple-icon-57x57.png" />
                <link rel="apple-touch-icon" sizes="60x60" href="../assets/favicon/apple-icon-60x60.png" />
                <link rel="apple-touch-icon" sizes="72x72" href="../assets/favicon/apple-icon-72x72.png" />
                <link rel="apple-touch-icon" sizes="76x76" href="../assets/favicon/apple-icon-76x76.png" />
                <link rel="apple-touch-icon" sizes="114x114" href="../assets/favicon/apple-icon-114x114.png" />
                <link rel="apple-touch-icon" sizes="120x120" href="../assets/favicon/apple-icon-120x120.png" />
                <link rel="apple-touch-icon" sizes="144x144" href="../assets/favicon/apple-icon-144x144.png" />
                <link rel="apple-touch-icon" sizes="152x152" href="../assets/favicon/apple-icon-152x152.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="../assets/favicon/apple-icon-180x180.png" />
                <link rel="icon" type="image/png" sizes="192x192" href="../assets/favicon/android-icon-192x192.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="../assets/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="96x96" href="../assets/favicon/favicon-96x96.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="../assets/favicon/favicon-16x16.png" />
                <link rel="manifest" href="../assets/favicon/manifest.json" />
                <link rel="canonical" href="https://dreamhome19.bg/" />
                <meta charSet="utf-8" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="../assets/favicon/ms-icon-144x144.png" />
                <meta name="theme-color" content="#ffffff"></meta>
                <meta name="description"
                    content="Намерете услуги за търговски недвижими имоти и стратегии за инвестиции в имоти. Работим със собственици, наематели и инвеститори на местните и национални пазари." />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0, user-scalable=0" />
                <meta name="keywords" content="Недвижими имоти, продажба, под наем" />
                <meta name="author" content="Drem Home-19" />
                <meta property="og:title" content="Drem Home-19 | За нас" />
                <meta property="og:description"
                    content="Намерете услуги за търговски недвижими имоти и стратегии за инвестиции в имоти. Работим със собственици, наематели и инвеститори на местните и национални пазари." />
                <meta property="og:image" content="../../assets/logo/logoDreamHome19.svg" />
                <meta name="prerender-status-code" content="404"></meta>
            </Helmet>

            <main className='about-us-main'>
                <Container fluid>
                    <Row className={animate ? "animate" : ""}>
                        <Main title1='ЗА' title2='НАС' subtitle='Oткрийте мечтаното жилище с нас' button='Намери' className='about-us' />
                    </Row>
                </Container>
            </main>

            <article className="about-us-page-container fade-in">
                <Container>
                    <Row>
                        <Col>
                            <h2>Как работим</h2>
                            <p>В зависимост от това дали купувате, продавате, искате на наемете или да дадете под наем, ние имаме професионално изградена система от процедури за постигане на максимална ефективност при процеса.</p>

                            <h2>Какво ще получите ако работите с нас</h2>
                            <p>Нашият специализиран екип, ще Ви даде цялата необходима информация при покупка или продажба на имот и ще Ви напътства през целия процес на покупка. </p>
                            <p>
                            Ние разширяваме предлагането на недвижими имоти ежедневно, така че следете ни, разгледайте нашия уебсайт или се свържете директно с нас на +359 898 724 644.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <BaseButton title="Вижте нашите оферти" onClick={handleClick}></BaseButton>
                        </Col>
                    </Row>
                </Container>
            </article>
            {/* <Footer /> */}
        </>
    );
};

export default AboutUsPage;
