import React from 'react';
// import ReactDOM from 'react-dom'
import { hydrate, render } from "react-dom";
import SSRProvider from 'react-bootstrap/SSRProvider';
import './index.scss';
// import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import reducers from './reducers'

import App from './App';

const preloadedState = window.__PRELOADED_STATE__;

delete window.__PRELOADED_STATE__
const store = createStore(reducers, preloadedState || compose(applyMiddleware(thunk)))

window.snapSaveState = () => ({
  __PRELOADED_STATE__: store.getState()
});


const rootElement = document.getElementById("root");

// import reportWebVitals from './reportWebVitals';

// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <SSRProvider>
//       {/* <Provider store={store}> */}
//         <App />
//       {/* </Provider> */}
//     </SSRProvider>
//     , rootElement
//   );
// } else {
//   render(
//     <SSRProvider>
//       {/* <Provider store={store}> */}
//         <App />
//       {/* </Provider> */}
//     </SSRProvider>
//     , rootElement
//   );
// }

if (rootElement.hasChildNodes()) {
  hydrate(<SSRProvider><App /></SSRProvider>, rootElement);
} else {
  render(<SSRProvider><App /></SSRProvider>, rootElement);
}

// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById('root')
// );

// reportWebVitals();


