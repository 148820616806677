import { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import ProductPrice from '../product-price'
import './styles.scss'
import { Link } from 'react-router-dom'

const ProductImage = ({ image, title }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <img src={image} alt={title} className='product-image' onClick={scrollToTop} />
  )
}

const ProductCard = ({ image, title, subtitle, status, price, area, id, className }) => {

  const [isUnavailable, setIsUnavailable] = useState([]);

  useEffect(() => {
    setIsUnavailable(status === "Продажба" || status === "Под наем")
  }, [status]);

  return (

    <div className={isUnavailable ? "product-card" : "product-card sold"}>
      <div className='ribbon'> {status}</div>
      {isUnavailable ?
        <Link to={`/product/${id}`} className='product-image'>
          <ProductImage image={image} title={title} />
        </Link>
        :
        <div to={`/product/${id}`} className='product-image'>
          <ProductImage image={image} title={title} />
        </div>}
      <div className="product-card-info">
        <span className="product-title">{title}&#44;&nbsp;<span>{subtitle}</span></span>
        <div className="product-info">
          <p><span>{area}</span> кв.м</p>
          <ProductPrice price={price} />
        </div>
      </div>
    </div>
  )
}

ProductCard.defaultProps = {
  image: '',
  title: 'Placeholder',
  price: '0'
}

ProductCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired
}

export default ProductCard