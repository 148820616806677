import './BaseImage.scss';

const BaseImage = ({ img, alt, width, height, className }) => {

  return (
    <div className={`image-wrapper ${className}`}>
      <picture >
        <img src={img} alt={alt} width={width} height={height}></img>
      </picture>
    </div>
  )
}

export default BaseImage;